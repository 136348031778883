<typography-v2
  data-id="due-date"
  class="cursor-pointer"
  (click)="triggerDatepicker()"
  [ngClass]="{ 'text-red': node.dueDate | isDateBefore }"
  [tooltip]="node.dueDescription"
  placement="bottom"
  look="caption-bold"
>
  <due-date-badge-v2
    *ngIf="node.dueDate"
    [pressed]="showContextMenu"
    [value]="node.dueDate"
    look="header"
  ></due-date-badge-v2>
  <span *ngIf="!node.dueDate">Follow up</span>
</typography-v2>

<context-menu-v2
  *ngIf="showContextMenu"
  (click)="$event.stopPropagation()"
  (clickOutside)="closeDatepicker()"
  class="due-date-menu v2-p-4"
>
  <due-date-form-v3
    [data]="formData"
    [entityId]="'0'"
    (formSubmit)="onSubmit($event)"
  ></due-date-form-v3>
</context-menu-v2>
