<card [ngClass]="{ 'v2-p-0' : isOption }">
  <div class="d-flex align-items-center">
    <ng-container *ngIf="!isOption">
      <typography-v2 look="title-bold" data-id="title">Passengers</typography-v2>

      <typography-v2 look="title-bold" data-id="total-passengers" class="v2-ml-1 text-jazlyn-600"
        >{{ order.totalPassengers }}</typography-v2
      >
      <button-icon-v2
        *ngIf="!readonlyPage"
        [modalTpl]="sellingPriceTemplate"
        [key]="order?.isSoldAmountEditable ? 'edit-pen' : 'chevron-right'"
        modalClass="wider"
        showModalOnClick
        size="md"
        color="var(--color-black)"
        class="v2-ml-1 edit-passengers"
        data-id="edit-passengers"
      ></button-icon-v2>
    </ng-container>
  </div>

  <ng-container *ngIf="isOption">
    <typography-v2 look="text-bold" data-id="title">Passengers</typography-v2>
  </ng-container>

  <div
    *ngIf="order.passengers?.edges?.length; else emptyState"
    class="d-flex v2-mt-3 passengers-cards"
  >
    <div
      *ngFor="let passenger of order.passengers?.edges | extractNodes"
      class="d-flex align-items-center justify-content-between v2-px-3 v2-py-2 position-relative passenger-card"
      data-id="passenger"
    >
      <a
        (click)="showedPassengerDetails = passenger"
        class="d-flex align-items-center passenger-fullname"
        data-id="details-passenger"
      >
        <span class="text-truncate">{{ passenger.fullName }}</span>
        <rdr-svg-icon
          class="v2-ml-1"
          key="chevron-bottom"
          size="sm"
          color="var(--color-black)"
        ></rdr-svg-icon>
      </a>

      <context-menu-v2
        *ngIf="showedPassengerDetails === passenger"
        (clickOutside)="showedPassengerDetails = null"
        class="position-absolute passenger-details"
      >
        <passenger-details-card
          *ngIf="showedPassengerDetails"
          (downloadDocument)="downloadDocument.emit($event)"
          [passenger]="showedPassengerDetails"
          (closeDetails)="showedPassengerDetails = null"
        ></passenger-details-card>
      </context-menu-v2>

      <button-icon-v2
        *ngIf="!readonlyPage"
        (click)="setEditablePassenger(passenger)"
        [modalTpl]="updatePassengerTpl"
        [modalClass]="'extra-wide'"
        showModalOnClick
        key="edit-pen"
        size="md"
        color="var(--color-black)"
        class="v2-ml-1 edit-passenger"
        data-id="edit-passenger"
      ></button-icon-v2>
    </div>

    <div
      *ngIf="!readonlyPage && (order | needsToAddPassengers)"
      class="d-flex align-items-center justify-content-start v2-py-1 add-passenger-card"
    >
      <button-v2
        [modalTpl]="newPassengerTpl"
        [modalClass]="'extra-wide'"
        showModalOnClick
        type="filter"
        data-id="add-passenger"
        >Add Passenger</button-v2
      >
    </div>
  </div>
</card>

<ng-template #sellingPriceTemplate>
  <modal-v2 header="Passengers & Selling Price">
    <selling-price-form
      (formSubmit)="updatePassengers.emit($event)"
      [data]="{
        soldAmountPerAdult: order.soldAmountPerAdult,
        soldAmountPerChild: order.soldAmountPerChild,
        soldAmountPerInfant: order.soldAmountPerInfant,
        numberOfAdults: order.numberOfAdults,
        numberOfChildren: order.numberOfChildren,
        numberOfInfants: order.numberOfInfants
      }"
      [order]="order"
      [readonly]="!order.isSoldAmountEditable"
    ></selling-price-form>
  </modal-v2>
</ng-template>

<ng-template #updatePassengerTpl>
  <modal-v2 header="Edit Passenger">
    <passenger-form-v2
      (formSubmit)="savePassenger.emit($event)"
      (deletePassenger)="deletePassenger.emit(editablePassenger?.id)"
      [data]="editablePassenger"
      [entityId]="editablePassenger?.id"
      [allowDelete]="true"
    ></passenger-form-v2>
  </modal-v2>
</ng-template>

<ng-template #newPassengerTpl>
  <modal-v2 header="New Passenger">
    <passenger-form-v2
      (formSubmit)="savePassenger.emit($event)"
      [isOrder]="true"
      [clientId]="order.client?.id!"
      [passengers]="order.passengers?.edges | extractNodes"
      [totalPassengers]="order.totalPassengers"
    ></passenger-form-v2>
  </modal-v2>
</ng-template>

<ng-template #emptyState>
  <div
    *ngIf="!readonlyPage && (order | needsToAddPassengers)"
    class="d-flex align-items-center justify-content-start v2-py-1 v2-mt-3"
  >
    <button-v2
      [modalTpl]="newPassengerTpl"
      [modalClass]="'extra-wide'"
      showModalOnClick
      type="filter"
      data-id="add-passenger"
      >Add Passenger</button-v2
    >
  </div>
</ng-template>
