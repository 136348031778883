<rdr-svg-icon
  [color]="ancillary.status === AncillaryStatusTypeEnum.Purchased ? 'var(--color-lightgreen-active)' : 'var(--color-jazlyn-700)'"
  [key]="ancillary | ancillaryIcon"
  size="xll"
></rdr-svg-icon>

<div class="d-flex flex-column v2-ml-3">
  <typography-v2 class="text-jazlyn-700" dataId="price"
    >{{ ancillary.price | rdrCurrency }}</typography-v2
  >

  <typography-v2
    [ngClass]="ancillary.status === AncillaryStatusTypeEnum.Purchased ? 'text-lightgreen-active' : 'text-jazlyn-700'"
    dataId="status"
    >{{ ancillary.status | label }}</typography-v2
  >
</div>
