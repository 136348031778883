import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { OrderNode, OrderType, PassengerNode } from '@app/generated/graphql';
import { mapPassengerToFormData } from '@app/shared/components/passengers-section/passengers-section.component';

@Component({
  selector: 'order-passengers-section',
  templateUrl: './order-passengers-section.component.html',
  styleUrls: ['./order-passengers-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderPassengersSectionComponent {
  OrderType = OrderType;

  editablePassenger: PassengerNode & { phone: string; email: string };
  showedPassengerDetails: PassengerNode | null;

  @Input() order: OrderNode;
  @Input() readonlyPage: boolean;
  @Input() isOption: boolean;

  @Output() updatePassengers = new EventEmitter();
  @Output() savePassenger = new EventEmitter();
  @Output() deletePassenger = new EventEmitter();
  @Output() downloadDocument = new EventEmitter();

  setEditablePassenger(passenger: PassengerNode) {
    this.editablePassenger = mapPassengerToFormData(passenger);
  }
}
