import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import {
  BOOKING_COST,
  DATE_V3,
  INPUT_V2,
  NUMERIC_V2,
  REPEAT_V2,
  TEXTAREA_V2,
  TYPEAHEAD_V2,
} from '@app/formly/helpers/fields';
import {
  AirportsService,
  airportsDropDownMapper,
} from '@app/shared/services/airports/airports.service';
import { filterDDWithCode } from '@app/formly/types/v2/typeahead-v2/typeahead-filters';
import { AirportNode, CostNodeInput, CostTypeInput } from '@app/generated/graphql';
import { BookingFormAbstract } from '@app/pages/flight-requests/forms/booking-form-abstract';
import airports from 'static/airports';
import { UntilDestroy, UntilDestroyed } from '@app/shared/utils/until-destroy';

@UntilDestroy()
@Component({
  selector: 'booking-hotel-form',
  templateUrl: './booking-hotel-form.component.html',
  styleUrls: ['./booking-hotel-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingHotelFormComponent extends BookingFormAbstract implements OnInit {
  @Input() isOrder: boolean;
  @Input() guestsCount: number;

  constructor(private airportsService: AirportsService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.initCosts();

    this.fields = [
      INPUT_V2({
        key: 'name',
        props: {
          label: 'Hotel Name',
          placeholder: 'Hotel Name',
          required: true,
        },
      }),
      {
        fieldGroupClassName: 'd-flex w-100',
        fieldGroup: [
          TYPEAHEAD_V2(
            {
              key: 'airportId',
              className: 'col-6',
              props: {
                label: 'Airport',
                required: true,
                initial: this.data?.airport
                  ? airportsDropDownMapper(this.data.airport as AirportNode)
                  : null,
                filterCallback: filterDDWithCode,
                placeholder: 'Choose Ariport',
              },
              hooks: {
                onInit: () => {
                  this.formGroup
                    .get('airportId')
                    ?.valueChanges.pipe(UntilDestroyed(this))
                    .subscribe((id) => {
                      const found = airports.find((airport) => airport.value === id);

                      if (found) {
                        this.formGroup.get('city')?.setValue(found.extras.cityName);
                      }
                    });
                },
              },
            },
            this.airportsService.getAirportsAsDropDownItems(),
          ),
          INPUT_V2({
            key: 'city',
            className: 'col-6',
            props: {
              label: 'City',
              required: true,
              placeholder: 'City',
            },
          }),
        ],
      },
      {
        fieldGroupClassName: 'd-flex w-100',
        fieldGroup: [
          DATE_V3({
            key: 'checkInDate',
            className: 'col-4',
            props: {
              label: 'Check-in date',
              required: true,
            },
          }),
          DATE_V3({
            key: 'checkoutDate',
            className: 'col-4',
            props: {
              label: 'Check-out date',
              required: true,
            },
          }),
          NUMERIC_V2({
            key: 'guestCount',
            className: 'col-4',
            props: {
              label: 'Guests',
              required: true,
            },
            defaultValue: this.guestsCount,
          }),
        ],
      },
      REPEAT_V2({
        key: 'costs',
        props: {
          label: 'Costs',
          disabled: this.formGroup.disabled,
          addButtonLabel: 'Add Cost/Commission',
          addButtonDataid: 'add-cost',
        },
        validators: {
          validation: ['hotel-booking-cost'],
        },
        fieldArray: BOOKING_COST({
          key: 'cost',
          props: {
            disabled: this.formGroup.disabled,
            hotelBooking: true,
          },
        }),
        hideExpression: () => this.isOrder,
      }),
      TEXTAREA_V2({
        key: 'notes',
        props: {
          label: 'Note',
          placeholder: 'Note',
        },
      }),
    ];
  }

  initCosts() {
    const hasCosts = !!this.data?.costs?.length;

    if (!hasCosts) {
      const costs = [{ type: CostTypeInput.Hotel }];

      this.formModel = { ...this.formModel, costs };
    }
  }

  get totalAmount() {
    return this.formModel.costs
      ?.filter((cost: CostNodeInput) => !!cost)
      ?.filter((cost: CostNodeInput) => cost.type !== CostTypeInput.Commission)
      ?.reduce((acc: number, cost: CostNodeInput) => acc + Number(cost.unitAmount), 0);
  }
}
