<card>
  <div class="d-flex justify-content-between">
    <div class="d-flex">
      <div class="d-flex flex-column">
        <typography-v2>Current Itinerary</typography-v2>

        <ng-container *ngIf="createdByUserId && createdAt">
          <typography-v2
            *ngIf="createdByUserId | loadUserById | async as user"
            look="small"
            class="text-jazlyn-700"
            dataId="info"
            >{{ user.fullName | shortFullName }} created {{ createdAt | createdAt }}</typography-v2
          >
        </ng-container>
      </div>

      <div class="separator v2-mx-4"></div>

      <div class="d-flex flex-column">
        <typography-v2 dataId="passengers-count">{{ totalPassengersCount }}</typography-v2>
        <typography-v2 look="small" class="text-jazlyn-700">Passengers</typography-v2>
      </div>
    </div>

    <button-v2 (click)="newChangeRequestOption.emit()" type="filter" data-id="copy-to-new-option"
      >Copy to New Option</button-v2
    >
  </div>

  <hr class="v2-mt-4" />

  <passengers-section
    [passengers]="passengers"
    [readonlyPage]="true"
    [isOption]="true"
  ></passengers-section>

  <hr class="v2-my-4" />

  <typography-v2 look="text-bold">Bookings</typography-v2>

  <div class="d-flex overflow-auto v2-mt-2">
    <booking-card
      *ngIf="(pnrs?.length || 0) > 1"
      (click)="setActivePnr(null)"
      [bookingsLength]="pnrs?.length || 0"
      [activeBooking]="activePnr?.latestBooking || null"
      [readonlyPage]="true"
      data-id="entire-card"
      class="v2-mr-1"
    ></booking-card>

    <ng-container *ngFor="let pnr of pnrs; let first = first">
      <booking-card
        *ngIf="pnr && pnr.latestBooking"
        (click)="setActivePnr(pnr)"
        [booking]="pnr.latestBooking"
        [activeBooking]="activePnr?.latestBooking || null"
        [readonlyPage]="true"
        [ngClass]="{ 'v2-ml-1': !first }"
        data-id="booking-card"
      ></booking-card>
    </ng-container>
  </div>

  <div *ngIf="activePnr && activePnr.latestBooking; else entireTemplate" class="v2-mt-2">
    <ng-container [ngSwitch]="activePnr.latestBooking | bookingType">
      <div class="d-flex align-items-center">
        <booking-miles-head
          *ngSwitchCase="BookingType.MILES"
          [booking]="activePnr.latestBooking"
        ></booking-miles-head>

        <booking-revenue-head
          *ngSwitchCase="BookingType.REVENUE"
          [booking]="activePnr.latestBooking"
          [showOldPnrs]="true"
        ></booking-revenue-head>
      </div>
    </ng-container>

    <ng-container *ngIf="activePnr && activePnr.latestBooking">
      <ng-container [ngSwitch]="activePnr.latestBooking | bookingType">
        <hotel-itinerary
          *ngSwitchCase="BookingType.HOTEL"
          [booking]="activePnr.latestBooking"
        ></hotel-itinerary>

        <itinerary-v2-flights
          *ngSwitchDefault
          [flightItineraries]="activePnr.latestBooking?.flightItineraries"
          [booking]="activePnr.latestBooking"
          [readonly]="true"
          [readonlyExtra]="true"
        ></itinerary-v2-flights>
      </ng-container>
    </ng-container>
  </div>

  <ancillaries-tabs
    *ngIf="activePnr?.ancillaryPnrs?.edges?.length"
    [ancillaryPnrs]="activePnr?.ancillaryPnrs?.edges | extractNodes"
    class="v2-mt-4"
  ></ancillaries-tabs>
</card>

<ng-template #entireTemplate>
  <itinerary-v2-flights
    [flightItineraries]="changeRequestCurrentFlightItineraries"
    [showSeatsSelection]="true"
    [readonly]="true"
    [readonlyExtra]="true"
    class="v2-mt-3"
  ></itinerary-v2-flights>

  <ng-container *ngFor="let pnr of pnrs">
    <hotel-itinerary
      *ngIf="pnr.latestBooking && pnr.latestBooking.bookingHotel"
      [booking]="pnr.latestBooking"
      class="v2-mt-2"
    ></hotel-itinerary>
  </ng-container>
</ng-template>
