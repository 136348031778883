import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AncillaryNode } from '@app/generated/graphql';

@Component({
  selector: 'ancillary-details',
  templateUrl: './ancillary-details.component.html',
  styleUrls: ['./ancillary-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AncillaryDetailsComponent {
  @Input() ancillary: AncillaryNode;
}
