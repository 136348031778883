import { Pipe, PipeTransform } from '@angular/core';
import { OrderNode } from '@app/generated/graphql';

@Pipe({
  name: 'needsToAddPassengers',
})
export class NeedsToAddPassengersPipe implements PipeTransform {
  transform(order?: OrderNode) {
    const totalPassengers = order?.totalPassengers || 0;
    const hasPassengersCount = order?.passengers?.edges?.length || 0;

    return totalPassengers - hasPassengersCount > 0;
  }
}
