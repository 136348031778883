import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InteractionsComponent } from './components/interactions/interactions.component';
import { UiV2Module } from '@app/ui-v2/ui-v2.module';
import { FlightRequestStatusEventComponent } from './components/flight-request-status-event/flight-request-status-event.component';
import { LeadStatusEventComponent } from './components/lead-status-event/lead-status-event.component';
import { OrderStatusEventComponent } from './components/order-status-event/order-status-event.component';
import { PipesModule } from '@app/ui/pipes/pipes.module';
import { RouterModule } from '@angular/router';
import { IconsModule } from '@app/icons/icons.module';
import { LeadMessageEventComponent } from './components/lead-message-event/lead-message-event.component';
import { EmailMessageEventComponent } from './components/email-message-event/email-message-event.component';
import { CallEventComponent } from './components/call-event/call-event.component';
import { ReversePipe } from './pipes/reverse.pipe';
import { InteractionsLoadingComponent } from './components/interactions-loading/interactions-loading.component';
import { GetIconPipe } from './pipes/get-icon.pipe';
import { LeadCallTrackComponent } from './components/lead-call-track/lead-call-track.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { ChatMessageEventComponent } from './components/chat-message-event/chat-message-event.component';

const components = [
  InteractionsComponent,
  FlightRequestStatusEventComponent,
  LeadStatusEventComponent,
  OrderStatusEventComponent,
  LeadMessageEventComponent,
  EmailMessageEventComponent,
  CallEventComponent,
  InteractionsLoadingComponent,
  ReversePipe,
  GetIconPipe,
  LeadCallTrackComponent,
  SpinnerComponent,
  ChatMessageEventComponent,
];

@NgModule({
  declarations: components,
  imports: [CommonModule, UiV2Module, PipesModule, RouterModule, IconsModule],
  exports: components,
})
export class InteractionsModule {}
