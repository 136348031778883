<typography-v2 look="subtitle-bold" class="v2-ml-4">Interactions</typography-v2>

<div class="v2-mx-4 v2-mt-4 d-flex filters">
  <dropdown-v2
    [items]="communicationTypes"
    [isFilter]="true"
    [placeholder]="'Communication Type'"
    [selectedItems]="selectedCommunicationTypes"
    [multiselect]="true"
    (itemSelect)="setCommunicationType($event)"
    data-id="filter-type"
  >
  </dropdown-v2>
</div>

<div
  (click)="$event.stopPropagation(); $event.preventDefault()"
  class="scrollable position-relative v2-px-4 v2-mt-4"
  id="scrollable"
>
  <div *ngIf="!loadedAll && !loading" class="d-flex justify-content-center v2-my-4">
    <a (click)="loadMore()" data-id="load-more">View more activities</a>
  </div>

  <div *ngIf="loading && !interactions.length" [ngClass]="{ 'v2-mb-8': interactions.length }">
    <interactions-loading *ngIf="!interactions.length"></interactions-loading>
  </div>

  <div
    *ngIf="loading && interactions.length"
    class="d-flex align-items-center justify-content-center v2-my-4"
  >
    <spinner></spinner>
  </div>

  <div class="wrapper position-relative">
    <div *ngIf="!loading" class="line position-absolute"></div>

    <typography-v2 *ngIf="!interactions?.length && !loading"
      >No results, try changing filters.</typography-v2
    >

    <div
      *ngFor="let interaction of interactions | reverse; let index = index; let first = first"
      [ngClass]="{ 'v2-mt-8': !first }"
      class="d-flex"
      data-id="interaction"
    >
      <div class="d-flex align-items-center justify-content-center v2-mr-2 icon">
        <rdr-svg-icon
          [key]="interaction | getIcon"
          color="var(--color-jazlyn-700)"
          size="xl"
        ></rdr-svg-icon>
      </div>

      <call-event
        *ngIf="isWholeCalls(interaction)"
        [interaction]="interaction"
        [attr.data-index]="interactions.length - index"
        class="w-100"
      ></call-event>

      <lead-message-event
        *ngIf="isLeadMessageNode(interaction)"
        [interaction]="interaction"
        [attr.data-index]="interactions.length - index"
        class="w-100"
      ></lead-message-event>

      <email-message-event
        *ngIf="isEmailMessageNode(interaction)"
        [interaction]="interaction"
        [attr.data-index]="interactions.length - index"
        class="w-100"
      ></email-message-event>

      <lead-status-event
        *ngIf="isLeadStatusInteraction(interaction)"
        [interaction]="interaction"
        [attr.data-index]="interactions.length - index"
        class="w-100"
      ></lead-status-event>

      <flight-request-status-event
        *ngIf="isFlightRequestStatusInteraction(interaction)"
        [interaction]="interaction"
        [attr.data-index]="interactions.length - index"
        class="w-100"
      ></flight-request-status-event>

      <order-status-event
        *ngIf="isOrderStatusInteraction(interaction)"
        [interaction]="interaction"
        [attr.data-index]="interactions.length - index"
        class="w-100"
      ></order-status-event>

      <chat-message-event
        *ngIf="isChatMessageInteraction(interaction)"
        [interaction]="interaction"
        [attr.data-index]="interactions.length - index"
        class="w-100"
      ></chat-message-event>
    </div>
  </div>
</div>
