<div class="d-flex">
  <typography-v2>Price:&nbsp;</typography-v2>
  <typography-v2 dataId="price" look="text-semibold"
    >{{ ancillary.price | rdrCurrency }}</typography-v2
  >
</div>
<div class="d-flex v2-mt-1">
  <typography-v2>Cost:&nbsp;</typography-v2>
  <typography-v2 dataId="price" look="text-semibold"
    >{{ ancillary.cost | rdrCurrency }}</typography-v2
  >
</div>
<div class="d-flex v2-mt-1">
  <typography-v2>Gross profit:&nbsp;</typography-v2>
  <typography-v2 dataId="price" look="text-semibold"
    >{{ ancillary.grossProfit | rdrCurrency }}</typography-v2
  >
</div>
