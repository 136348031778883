<ng-container *ngIf="column.cellType === RadarTableColumnType.Selection; else nonSelection">
  <div class="content">
    <radar-table-checkbox
      (click)="$event.stopPropagation()"
      (check)="onSelect()"
      [checked]="selected"
    ></radar-table-checkbox>
  </div>
</ng-container>

<ng-template #nonSelection>
  <div
    class="content"
    [ngClass]="[column.cellType || '', column.sort ? 'sortable' : '']"
    (click)="onSort()"
    [attr.data-id]="column.name"
  >
    <div>{{ column.name }}</div>

    <ng-container *ngIf="column.sort">
      <ng-container *ngIf="sort === column.sort.asc">
        <rdr-svg-icon color="var(--gray-600)" key="chevron-top" class="icon"></rdr-svg-icon>
      </ng-container>

      <ng-container *ngIf="sort === column.sort.desc">
        <rdr-svg-icon color="var(--gray-600)" key="chevron-bottom" class="icon"></rdr-svg-icon>
      </ng-container>
    </ng-container>
  </div>
</ng-template>
