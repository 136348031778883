import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  TemplateRef,
  HostBinding,
} from '@angular/core';
import {
  RadarTableColumn,
  RadarTableRow,
  RadarTableRowClick,
  RadarTableSort,
} from '../radar-table.types';

@Component({
  selector: 'radar-table',
  templateUrl: './radar-table.component.html',
  styleUrls: ['./radar-table.component.scss', './skins/radar-table.component.card-rows.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadarTableComponent implements OnChanges {
  @HostBinding('class.is-readonly') @Input() readonly: boolean;
  @HostBinding('class.headersStickied') @Input() stickHeaders: boolean;
  @HostBinding('class.columnsFixed') @Input() columnsFixed: boolean;
  @Input() skin: 'default' | 'card-rows' = 'default';
  @Input() columns: RadarTableColumn[];
  @Input() rows: RadarTableRow[];
  @Input() sort: RadarTableSort;
  @Input() selected?: RadarTableRow[];
  @Input() rowLink: string; /** usage rowLink="/invoices/{id}" where {id} - key in row */
  @Input() rowHoverContextMenuTpl: TemplateRef<any>;
  @Input() hoveredRow?: RadarTableRow;
  @Input() rowDetailsTemplate?: TemplateRef<any>;

  @Output() selection: EventEmitter<RadarTableRow[]> = new EventEmitter();
  @Output() sorting: EventEmitter<RadarTableSort> = new EventEmitter();
  @Output() rowClick: EventEmitter<RadarTableRowClick> = new EventEmitter();
  @Output() rowHover: EventEmitter<RadarTableRowClick> = new EventEmitter<RadarTableRowClick>();

  selectedRows: Set<RadarTableRow> = new Set();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selected) {
      this.selectedRows = new Set([...(this.selected || [])]);
    }
  }

  isSelectedRow(row: RadarTableRow): boolean {
    return this.selectedRows.has(row);
  }

  onSelectRow(row: RadarTableRow): void {
    if (this.selectedRows.has(row)) {
      this.selectedRows.delete(row);
    } else {
      this.selectedRows.add(row);
    }

    this.selection.emit(Array.from(this.selectedRows));
  }

  onSelectAll() {
    this.rows.forEach((row) => this.selectedRows.add(row));
    this.selection.emit(Array.from(this.selectedRows));
  }

  onSort(sort: RadarTableSort): void {
    this.sorting.emit(sort);
  }

  onHover(event: MouseEvent, row: RadarTableRow) {
    this.hoveredRow = row;
    this.rowHover.emit({ event, row });
  }

  onRowClick(event: MouseEvent, row: RadarTableRow): void {
    this.rowClick.emit({ event, row });
  }
}
