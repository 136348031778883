import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  Input,
} from '@angular/core';
import { ChatMessageNode } from '@app/generated/graphql';

@Component({
  selector: 'chat-message-event',
  templateUrl: './chat-message-event.component.html',
  styleUrls: ['./chat-message-event.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatMessageEventComponent {
  expanded = false;

  @Input() interaction: ChatMessageNode & { chatSender: string };

  @HostBinding('class.cursor-pointer') get classes() {
    return !this.expanded;
  }

  @HostListener('click') click() {
    if (!this.expanded) {
      this.expanded = true;
    }
  }
}
