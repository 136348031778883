<div class="d-flex flex-column">
  <div class="d-flex justify-content-between">
    <typography-v2 class="text-jazlyn-700" look="small">
      {{ interaction.chatSender }}
    </typography-v2>

    <typography-v2 class="text-jazlyn-700" look="small" dataId="datetime-at"
      >{{ interaction.createdAt | rdrDate: 'extended' }}</typography-v2
    >
  </div>

  <div *ngIf="!expanded" class="d-flex">
    <typography-v2 class="message text-truncate w-580" dataId="content"
      ><span [innerHtml]="interaction.htmlMessage"></span
    ></typography-v2>

    <a class="view text-purple v2-ml-1">View</a>
  </div>

  <ng-container *ngIf="expanded">
    <typography-v2 class="message content text-truncate w-580" dataId="content">
      <span [innerHtml]="interaction.htmlMessage"></span>
    </typography-v2>

    <div class="v2-mt-2">
      <a (click)="$event.stopPropagation(); expanded = false" class="text-purple">Hide</a>
    </div>
  </ng-container>
</div>
