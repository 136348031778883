import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AncillaryPnrNode, BookingChangeAncillaryDraftNode } from '@app/generated/graphql';

@Component({
  selector: 'ancillaries-tabs',
  templateUrl: './ancillaries-tabs.component.html',
  styleUrls: ['./ancillaries-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AncillariesTabsComponent {
  @Input() ancillaryPnrs: (AncillaryPnrNode | BookingChangeAncillaryDraftNode)[];
  @Input() editable: boolean;

  @Output() changeStatus = new EventEmitter();
}
