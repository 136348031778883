<div class="d-flex align-items-center">
  <typography-v2 (click)="expanded = !expanded" look="text-semibold" class="cursor-pointer"
    >{{ ancillaryPnr.ancillary?.type | label }}</typography-v2
  >

  <rdr-svg-icon
    (click)="expanded = !expanded"
    [ngClass]="{ 'rotated': !expanded }"
    key="chevron-bottom-v-2"
    color="var(--color-black-50)"
    class="v2-ml-2 cursor-pointer"
    size="sm"
  ></rdr-svg-icon>

  <div *ngIf="editable; else default" class="v2-ml-2 dd">
    <dropdown-v2
      [className]="ancillaryPnr.status | lowercase"
      [selectedItem]="selectedDropdownOption"
      [items]="statusDropdownOptions"
      (itemSelect)="handleChange($event)"
      look="default-slim"
    ></dropdown-v2>
  </div>

  <ng-template #default>
    <status-badge [status]="ancillaryPnr.status" class="v2-ml-2"></status-badge>
  </ng-template>
</div>

<ancillary-details
  *ngIf="ancillaryPnr.ancillary && expanded"
  [ancillary]="ancillaryPnr.ancillary"
  class="v2-mt-2"
></ancillary-details>
