import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { AncillaryNode, AncillaryStatusTypeEnum } from '@app/generated/graphql';

@Component({
  selector: 'ancillary-card',
  templateUrl: './ancillary-card.component.html',
  styleUrls: ['./ancillary-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AncillaryCardComponent {
  AncillaryStatusTypeEnum = AncillaryStatusTypeEnum;

  @Input() ancillary: AncillaryNode;
  @HostBinding('class.selected') @Input() selected: boolean;
}
