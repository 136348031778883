import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  FlightItineraryGroupNode,
  PassengerGroupPnrNode,
  PassengerNode,
} from '@app/generated/graphql';
import { BookingType } from '@app/shared/models/booking-type.enum';

@Component({
  selector: 'current-itinerary',
  templateUrl: './current-itinerary.component.html',
  styleUrls: ['./current-itinerary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrentItineraryComponent implements OnInit {
  BookingType = BookingType;

  activePnr: PassengerGroupPnrNode | null = null;

  @Input() totalPassengersCount?: number;
  @Input() createdByUserId?: number;
  @Input() createdAt?: Date;
  @Input() pnrs: PassengerGroupPnrNode[];
  @Input() passengers: PassengerNode[];
  @Input() changeRequestCurrentFlightItineraries?: FlightItineraryGroupNode[];

  @Output() newChangeRequestOption = new EventEmitter();

  ngOnInit() {
    this.setActivePnr(this.pnrs?.length > 1 ? null : this.pnrs[0]);
  }

  setActivePnr(activePnr: PassengerGroupPnrNode | null) {
    this.activePnr = activePnr;
  }

  resetActivePnr() {
    this.activePnr = null;
  }
}
