import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  AncillaryPnrNode,
  AncillaryPnrStatusTypeEnum,
  BookingChangeAncillaryDraftNode,
  ChangeAncillaryDraftStatusMutationVariables,
} from '@app/generated/graphql';
import { mapStatusToDropdownItem } from '@app/shared/utils/dropdown-utils';
import { DropdownItem } from '@app/ui/components/dropdown.model';

@Component({
  selector: 'ancillary-tab',
  templateUrl: './ancillary-tab.component.html',
  styleUrls: ['./ancillary-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AncillaryTabComponent implements OnInit {
  AncillaryPnrStatusTypeEnum = AncillaryPnrStatusTypeEnum;

  expanded: boolean;

  statusDropdownOptions: DropdownItem[];
  selectedDropdownOption: DropdownItem;

  @Input() ancillaryPnr: AncillaryPnrNode | BookingChangeAncillaryDraftNode;
  @Input() editable: boolean;

  @Output() changeStatus = new EventEmitter();

  ngOnInit() {
    this.statusDropdownOptions = [
      AncillaryPnrStatusTypeEnum.Active,
      AncillaryPnrStatusTypeEnum.Inactive,
      AncillaryPnrStatusTypeEnum.Used,
    ].map((s) => mapStatusToDropdownItem<AncillaryPnrStatusTypeEnum>(s));

    this.selectedDropdownOption = mapStatusToDropdownItem<AncillaryPnrStatusTypeEnum>(
      this.ancillaryPnr.status as unknown as AncillaryPnrStatusTypeEnum,
    );
  }

  handleChange(status: DropdownItem) {
    this.changeStatus.emit({
      id: this.ancillaryPnr.id,
      newStatus: status.value,
    } as ChangeAncillaryDraftStatusMutationVariables);
  }
}
