<div
  [ngClass]="{ 'selected': (activeBooking?.id === booking?.id) || (activeBooking === null && !booking)  }"
  class="d-flex v2-p-3 cursor-pointer position-relative nowrap card"
>
  <ng-container *ngIf="booking; else entire">
    <ng-container [ngSwitch]="booking | bookingType">
      <ng-container *ngSwitchCase="BookingType.MILES">
        <ng-container *ngTemplateOutlet="miles"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="BookingType.REVENUE">
        <ng-container *ngIf="booking?.bookingRevenue?.isDemo; else revenue">
          <ng-container *ngTemplateOutlet="demo"></ng-container>
        </ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="BookingType.HOTEL">
        <ng-container *ngTemplateOutlet="hotel"></ng-container>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="!readonlyPage">
    <div class="v2-px-1 control position-absolute">
      <button-icon-v2
        (click)="setEditableBooking.emit()"
        key="edit-pen"
        size="md"
        color="var(--color-black)"
        data-id="edit"
      ></button-icon-v2>
    </div>
  </ng-container>

  <ng-container *ngIf="withActions">
    <div class="v2-px-1 control position-absolute">
      <button-icon-v2
        (click)="isShowActions = true"
        key="more"
        size="md"
        color="var(--color-jazlyn-700)"
        data-id="more"
      ></button-icon-v2>
    </div>

    <context-menu-v2
      *ngIf="isShowActions"
      (clickOutside)="isShowActions = false"
      class="position-absolute actions"
    >
      <ng-container *showForRole="UserType.TicketingAgent">
        <dropdown-item-v2
          *ngIf="booking.scheduleChange; else registerScheduleChangeTemplate"
          [confirmOnDelete]="' '"
          [confirmMessage]="' '"
          [confirmHeader]="'Are you sure you want to accept schedule change?'"
          [confirmText]="'Accept'"
          [confirmButtonType]="'secondary'"
          (deleteConfirmed)="acceptScheduleChange.emit(); isShowActions = false"
          data-id="accept-schedule-change"
          class="d-flex align-items-center"
          hoverClass
        >
          <rdr-svg-icon
            key="schedule-change"
            class="v2-mr-2"
            color="var(--color-jazlyn-700)"
            size="lg"
          ></rdr-svg-icon>
          Accept Schedule Change
        </dropdown-item-v2>

        <ng-template #registerScheduleChangeTemplate>
          <dropdown-item-v2
            (click)="registerScheduleChange.emit(); isShowActions = false"
            data-id="register-schedule-change"
            class="d-flex align-items-center"
            hoverClass
          >
            <rdr-svg-icon
              key="schedule-change"
              class="v2-mr-2"
              color="var(--color-jazlyn-700)"
              size="lg"
            ></rdr-svg-icon>
            Register Schedule Change
          </dropdown-item-v2>
        </ng-template>
      </ng-container>

      <dropdown-item-v2
        *ngIf="withSplitAction && !booking.scheduleChange"
        (click)="splitPassengers.emit(); isShowActions = false"
        data-id="split"
        class="d-flex align-items-center"
        hoverClass
      >
        <rdr-svg-icon
          key="split"
          class="v2-mr-2"
          color="var(--color-jazlyn-700)"
          size="lg"
        ></rdr-svg-icon>
        Split passengers
      </dropdown-item-v2>
    </context-menu-v2>
  </ng-container>
</div>

<ng-template #miles>
  <img src="assets/images/miles-booking.svg" width="36" height="36" class="v2-mr-3" />
  <div class="d-flex flex-column">
    <div class="d-flex align-items-center">
      <ng-container
        *ngTemplateOutlet="changeRequestType; context: { booking, valuesChanged: booking.bookingMiles?.valuesChanged }"
      ></ng-container>

      <typography-v2 dataId="total-cost" container="body" tooltip="Total cost"
        >{{ (totalCost || booking.totalCost) | rdrCurrency }}</typography-v2
      >

      <ng-container *ngIf="withStatus && booking?.status">
        <span class="v2-mx-2 text-jazlyn-700">&middot;</span>
        <typography-v2 dataId="status" class="{{ booking?.status | lowercase }}"
          >{{ booking?.status | label }}</typography-v2
        >
      </ng-container>
    </div>

    <div class="d-flex mt-auto">
      <ng-container
        *ngIf="booking.firstFlightItinerary?.departureAirport?.locationCode && booking.firstFlightItinerary?.arrivalAirport?.locationCode"
      >
        <typography-v2 class="text-jazlyn-700"
          ><span data-id="departure-airport"
            >{{ booking.firstFlightItinerary?.departureAirport?.locationCode }}</span
          >
          &rarr;
          <span data-id="arrival-airport"
            >{{ booking.firstFlightItinerary?.arrivalAirport?.locationCode }}</span
          ></typography-v2
        >
      </ng-container>

      <ng-container *ngIf="(totalMiles || totalMiles === 0) || booking.bookingMiles?.milesAmount">
        <span
          *ngIf="booking.firstFlightItinerary?.departureAirport?.locationCode && booking.firstFlightItinerary?.arrivalAirport?.locationCode"
          class="v2-mx-2"
          >&middot;</span
        >
        <typography-v2
          container="body"
          tooltip="Miles amount"
          placement="top"
          dataId="miles-amount"
          class="text-jazlyn-700"
          >{{ ((totalMiles || totalMiles === 0) ? totalMiles : booking.bookingMiles?.milesAmount) |
          number }}</typography-v2
        >
      </ng-container>

      <ng-container *ngIf="booking.bookingMiles?.milesProgram?.name">
        <span
          *ngIf="booking.firstFlightItinerary?.departureAirport?.locationCode || booking.firstFlightItinerary?.arrivalAirport?.locationCode || totalMiles || booking.bookingMiles?.milesAmount"
          class="v2-mx-2"
          >&middot;</span
        >
        <typography-v2
          container="body"
          tooltip="Miles program"
          placement="top"
          dataId="miles-program"
          class="text-jazlyn-700"
          >{{ booking.bookingMiles?.milesProgram?.name }}</typography-v2
        >
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #revenue>
  <img src="assets/images/revenue-booking.svg" width="36" height="36" class="v2-mr-3" />
  <div class="d-flex flex-column">
    <div class="d-flex align-items-center">
      <ng-container
        *ngTemplateOutlet="changeRequestType; context: { booking, valuesChanged: booking.bookingRevenue?.valuesChanged }"
      ></ng-container>

      <typography-v2 dataId="total-cost" container="body" tooltip="Total cost"
        >{{ (totalCost || booking.totalCost) | rdrCurrency }}</typography-v2
      >

      <ng-container *ngIf="withStatus && booking?.status">
        <span class="v2-mx-2 text-jazlyn-700">&middot;</span>
        <typography-v2 dataId="status" class="{{ booking?.status | lowercase }}"
          >{{ booking?.status | label }}</typography-v2
        >
      </ng-container>
    </div>

    <div class="d-flex align-items-center mt-auto">
      <ng-container
        *ngIf="booking.firstFlightItinerary?.departureAirport?.locationCode && booking.firstFlightItinerary?.arrivalAirport?.locationCode"
      >
        <typography-v2 class="text-jazlyn-700"
          ><span data-id="departure-airport"
            >{{ booking.firstFlightItinerary?.departureAirport?.locationCode }}</span
          >
          &rarr;
          <span data-id="arrival-airport"
            >{{ booking.firstFlightItinerary?.arrivalAirport?.locationCode }}</span
          ></typography-v2
        >
      </ng-container>

      <ng-container *ngIf="booking.bookingRevenue?.pcc?.name">
        <span
          *ngIf="booking.firstFlightItinerary?.departureAirport?.locationCode && booking.firstFlightItinerary?.arrivalAirport?.locationCode"
          class="v2-mx-2"
          >&middot;</span
        >
        <typography-v2
          container="body"
          tooltip="PCC"
          placement="top"
          dataId="pcc"
          class="text-jazlyn-700"
          >{{ booking.bookingRevenue?.pcc?.name }}</typography-v2
        >
      </ng-container>

      <ng-container *ngIf="booking.bookingRevenue?.fareType">
        <span class="v2-mx-2">&middot;</span>
        <typography-v2
          container="body"
          tooltip="Fare type"
          placement="top"
          dataId="fare-type"
          class="text-jazlyn-700"
          >{{ booking.bookingRevenue?.fareType | label }}</typography-v2
        >
      </ng-container>

      <ng-container *ngFor="let ancillaryPnr of ancillaryPnrs">
        <rdr-svg-icon
          [key]="ancillaryPnr.ancillary | ancillaryIcon"
          [color]="ancillaryPnr.status === AncillaryPnrStatusTypeEnum.Active ? 'var(--color-lightgreen-active)' : ancillaryPnr.status === AncillaryPnrStatusTypeEnum.Inactive ? 'var(--cherry-red)' : 'var(--color-jazlyn-700)'"
          class="v2-ml-1"
          size="lg"
        ></rdr-svg-icon>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #demo>
  <img src="assets/images/demo-booking.svg" width="36" height="36" class="v2-mr-3" />
  <div class="d-flex flex-column">
    <div class="d-flex align-items-center">
      <typography-v2 dataId="demo">Demo</typography-v2>

      <ng-container *ngIf="booking?.status">
        <span class="v2-mx-2 text-jazlyn-700">&middot;</span>
        <typography-v2 dataId="status" class="{{ booking?.status | lowercase }}"
          >{{ booking?.status | label }}</typography-v2
        >
      </ng-container>
    </div>

    <div class="d-flex mt-auto">
      <ng-container
        *ngIf="booking.firstFlightItinerary?.departureAirport?.locationCode && booking.firstFlightItinerary?.arrivalAirport?.locationCode"
      >
        <typography-v2 class="text-jazlyn-700"
          ><span data-id="departure-airport"
            >{{ booking.firstFlightItinerary?.departureAirport?.locationCode }}</span
          >
          &rarr;
          <span data-id="arrival-airport"
            >{{ booking.firstFlightItinerary?.arrivalAirport?.locationCode }}</span
          ></typography-v2
        >
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #hotel>
  <img src="assets/images/hotel-booking.svg" width="36" height="36" class="v2-mr-3" />
  <div class="d-flex flex-column">
    <div class="d-flex align-items-center">
      <ng-container
        *ngTemplateOutlet="changeRequestType; context: { booking, valuesChanged: booking.bookingHotel?.valuesChanged }"
      ></ng-container>

      <typography-v2 dataId="total-cost" container="body" tooltip="Total cost"
        >{{ (totalCost || booking.totalCost) | rdrCurrency }}</typography-v2
      >
    </div>

    <div class="d-flex mt-auto">
      <typography-v2
        container="body"
        tooltip="Booking dates"
        placement="top"
        class="text-jazlyn-700"
        ><span data-id="check-in"
          >{{ booking.bookingHotel?.checkInDate | rdrDate: 'date_only' }}</span
        >
        –
        <span data-id="check-out"
          >{{ booking.bookingHotel?.checkoutDate | rdrDate: 'date_only' }}</span
        ></typography-v2
      >

      <span class="v2-mx-2 text-jazlyn-700">&middot;</span>
      <typography-v2
        container="body"
        tooltip="Hotel Name"
        placement="top"
        dataId="hotel-name"
        class="text-jazlyn-700"
        >{{ booking.bookingHotel?.name }}</typography-v2
      >
    </div>
  </div>
</ng-template>

<ng-template #entire>
  <div class="d-flex flex-column">
    <typography-v2>Entire Trip</typography-v2>

    <typography-v2 class="text-jazlyn-700 mt-auto"
      >{{ bookingsLength }}
      <ng-container [ngPlural]="bookingsLength">
        <ng-template ngPluralCase="=1">booking</ng-template>
        <ng-template ngPluralCase="other">bookings</ng-template>
      </ng-container></typography-v2
    >
  </div>
</ng-template>

<ng-template #changeRequestType let-booking="booking" let-valuesChanged="valuesChanged">
  <ng-container *ngIf="booking.previousBookingId">
    <ng-container *ngIf="booking.scheduleChange; else noScheduleChange">
      <rdr-svg-icon
        key="schedule-change"
        size="lg"
        color="var(--color-sun-crete-700)"
        class="d-inline"
      ></rdr-svg-icon>
      <typography-v2 class="v2-ml-1 text-sun-crete-700" dataId="change-mark">
        Schedule Change
      </typography-v2>
    </ng-container>
    <ng-template #noScheduleChange>
      <ng-container *ngIf="valuesChanged">
        <ng-container *ngIf="booking.isCancellation">
          <rdr-svg-icon
            key="block"
            size="lg"
            color="var(--color-permanent-geranium-lake-700)"
            class="d-inline"
          ></rdr-svg-icon>
          <typography-v2 class="v2-ml-1 text-permanent-geranium-lake-700" dataId="change-mark">
            Cancellation
          </typography-v2>
        </ng-container>
        <ng-container *ngIf="!booking.isCancellation">
          <rdr-svg-icon
            key="change"
            size="lg"
            color="var(--color-sun-crete-700)"
            class="d-inline"
          ></rdr-svg-icon>
          <typography-v2 class="v2-ml-1 text-sun-crete-700" dataId="change-mark"
            >Change</typography-v2
          >
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!valuesChanged">
        <rdr-svg-icon
          key="info"
          size="lg"
          color="var(--color-jazlyn-700)"
          class="d-inline"
        ></rdr-svg-icon>
        <typography-v2 class="v2-ml-1 text-jazlyn-700" dataId="change-mark"
          >Unchanged</typography-v2
        >
      </ng-container>
    </ng-template>
    <span class="v2-mx-2 text-jazlyn-700">&middot;</span>
  </ng-container>
</ng-template>
