import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FrequentFlyerNumberNode, PassengerNode } from '@app/generated/graphql';
import { FormSubmitData } from '@app/shared/components/radar-form-abstract';
import { GraphqlID } from '@app/shared/types';

@Component({
  selector: 'passengers-section',
  templateUrl: './passengers-section.component.html',
  styleUrls: ['./passengers-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PassengersSectionComponent {
  editablePassenger: PassengerNode & {
    phone: string;
    email: string;
    flyerNumbers: FrequentFlyerNumberNode[];
  };

  showedPassengerDetails: PassengerNode | null;

  @Input() passengers: PassengerNode[];
  @Input() isOrder: boolean;
  @Input() clientId: GraphqlID;
  @Input() readonlyPage: boolean;
  @Input() isOption: boolean;

  @Output() savePassenger: EventEmitter<FormSubmitData> = new EventEmitter();
  @Output() deletePassenger: EventEmitter<string> = new EventEmitter();

  @Output() downloadDocument: EventEmitter<string> = new EventEmitter();

  setEditablePassenger(passenger: PassengerNode) {
    this.editablePassenger = mapPassengerToFormData(passenger);
  }
}

export const mapPassengerToFormData = (passenger: PassengerNode) => {
  const phone = passenger.phones?.length ? passenger.phones[0] : '';
  const email = passenger.emails?.length ? passenger.emails[0] : '';
  const flyerNumbers =
    passenger.frequentFlyerNumbers?.edges?.map(
      ({ node }) =>
        ({
          airlineId: node?.airlineId,
          number: node?.number,
        }) as FrequentFlyerNumberNode,
    ) || [];

  return {
    ...passenger,
    phone,
    email,
    flyerNumbers,
  };
};
